import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static values = {
    patientAgreementId: Number
  }

  static targets = [
  ]

  connect() {
    $(this.element).on("keyup", ".item__amount", (event) => {
      this.sumLineItems(event);
    })

    $(this.element).on("keyup", ".tab-control", () => {
      this.autoTab()
    })

    $(this.element).on("change", ".tab-control", () => {
      this.autoTab()
    })

    $('#card_type').select2({ minimumResultsForSearch: Infinity });
    $('#card_type').on('select2:select', () => $('#card_number').focus());
    $('#expiry_month').select2({ minimumResultsForSearch: Infinity });
    $('#expiry_month').on('select2:select', () => {
      $('#expiry_year').select2('focus');
      $('#expiry_year').select2('open');
    });
    $('#expiry_year').select2({ minimumResultsForSearch: Infinity });
    $('#expiry_year').on('select2:select', () => $('#cvv').focus());
  }

  toggleReceiptEmailCheckbox(event) {
    const $target = $(event.target);

    if ($target.prop("checked") === true) {
      $('.receipt-email').removeClass('d-none');
      $('.receipt_email').trigger('focus');
    } else {
      $('.receipt-email').addClass('d-none');
    }
  }

  _toggleSubmitChargeButton(event) {
    const $ul = $(event.target).closest("ul.product-list");
    const $buttons = $("#submit-charge,#submit-payment-request,#record-charge,#one-time-payment");
    if ($ul.find(".product-list__item input:checkbox:checked").length && ($('.overprice-warning').length === $('.overprice-warning.d-none').length)) {
      $buttons.prop("disabled", false);
    } else {
      $buttons.prop("disabled", true);
    }
  }

  toggleLineItemCheckbox(event) {
    this._toggleSubmitChargeButton(event);
    const $target = $(event.target);
    const $item_amount = $target.closest('.product-list__item').find('.item__amount');
    $item_amount.prop('disabled', !$target.prop('checked'));
    this.sumLineItems(event);
  }

  sumLineItems(event) {
    let sum = 0.0;

    if ($('.djo-account-group').length) {
      const $target = $(event.target).closest(".momd-card__control-wrapper").find(".item__amount");

      if ($target.closest('.payment-method').data('is-direct') !== 1) {
        const papId = $target.data('pap-id');
        const inputPrice = parseFloat($target.val());
        const papBillCharge = parseFloat($target.data('price'));
        if (inputPrice > papBillCharge) {
          $target.closest('.momd-card__control-wrapper').find('.overprice-warning').removeClass('d-none');
          $target.addClass('styleguide-alert-danger-border');
          $('#submit-charge').prop('disabled', true);
        } else {
          $target.closest('.momd-card__control-wrapper').find('.overprice-warning').addClass('d-none');
          $target.removeClass('styleguide-alert-danger-border');
        }
      }
    }

    $(".product-list__item input:checkbox:checked").map(function() {
      const $item = $(this).closest('.product-list__item');
      const itemPrice = $item.find(".item__amount").val().replace("$", "");
      const parsedItemPrice = parseFloat(itemPrice, 10);
      if (isNaN(parsedItemPrice) || (!parsedItemPrice > 0)) {
        $('#submit-payment-request').prop('disabled', true);
        if (document.getElementById('charge-errors')) {
          return document.getElementById('charge-errors').classList.remove('d-none');
        }
      } else {
        $('#submit-payment-request').prop('disabled', false);
        if (document.getElementById('charge-errors')) {
          document.getElementById('charge-errors').classList.add('d-none');
        }
        return sum += parsedItemPrice;
      }
    });

    const fixedSum = sum.toFixed(2);
    $("#amount").val(fixedSum);
    return $("#preview-charge-amount").text(fixedSum);
  }

  autoTab(event) {
    const target = event.target;
    const currentId = $(target).attr('id');
    const nextField = $(target).data('tab-to');
    // dropdowns are being handled above
    if ((currentId === "card_number") && ($(target).val().length === this.maxDigitsIn().cardNumber)) {
      $(target).blur();
      $(nextField).select2('focus');
      $(nextField).select2('open');
    }
    if ((currentId === "cvv") && ($(target).val().length === this.maxDigitsIn().cvv)) {
      $(target).blur();
      $(nextField).focus();
    }
  }

  cancel() {
    $("#turbo-modal").modal("hide")
  }
}
