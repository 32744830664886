import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {}

  expand(event) {
    const $paCard = $(event.target).closest('.pa-card')
    $paCard.find('.pa-card__expanded').toggleClass('hidden d-none')
    $paCard.find('.control__expand').toggleClass('hidden d-none')
    $paCard.find('.control__collapse').toggleClass('hidden d-none').trigger('focus')
    $('.tooltip').tooltip('hide')
  }

  collapse(event) {
    const $paCard = $(event.target).closest('.pa-card')
    $paCard.find('.pa-card__expanded').toggleClass('hidden d-none')
    $paCard.find('.control__expand').toggleClass('hidden d-none').trigger('focus')
    $paCard.find('.control__collapse').toggleClass('hidden d-none')
    $('.tooltip').tooltip('hide')
  }
}
