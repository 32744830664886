import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    'criteria',
    'version',
    'patientAgreementId'
  ];

  connect() {
    this.initTypeahead();
  }

  initTypeahead() {
    $(this.criteriaTarget).typeahead({
      source: (query, result) => {
        this.getSource(query, (suggestions) => {
          result(suggestions);
        });
      },
      minLength: 3,
      highlight: true,
      autoselect: true,
      afterSelect: (item) => {
        this.createPaDiagnosis(this.resultMap[item].id);
      },
    });
  }

  async getSource(query, callback) {
    let fetchParams = `criteria=${this.criteriaTarget.value}&version=${this.versionTarget.value}`

    await fetch(`/admin/diagnoses/autocomplete?${fetchParams}`, {
      method: 'GET',
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then(response => response.json())
    .then(function(results) {
      this.resultValues = [];
      this.resultMap = {};

      results.forEach(result => {
        this.resultMap[result.autocomplete_name] = result;
        this.resultValues.push(result.autocomplete_name);
      });
    }.bind(this))

    callback(this.resultValues)
  }

  createPaDiagnosis(id) {
    let csrfToken = document.querySelector('meta[name="csrf-token"]').content;
    let fetchParams = `id=${id}&patient_agreement_id=${this.patientAgreementIdTarget.value}`

    fetch(`/admin/patient_agreements/diagnoses?${fetchParams}`, {
      method: 'POST',
      headers: {
        'Accept': 'text/vnd.turbo-stream.html',
        'X-CSRF-Token': csrfToken
      }
    })
    .then(response => response.text())
    .then(response => {
      window.Turbo.renderStreamMessage(response);
    })
    .catch(error => {
      console.log(error);
    });

    this.criteriaTarget.value = null;
  }
}
