import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    'form',
    'left',
    'right',
    'notApplicable',
    'removeBtn',
    'rentalProduct'
  ]

  static values = {
    bothAllowed: Boolean,
    orientation: String,
    timeOfService: String
  }

  connect() {
    this.setupInitialStates();
  }

  setupInitialStates() {
    switch (this.orientationValue) {
      case 'bilateral':
        this.leftTarget.click();
        this.rightTarget.click();
        break;
      case 'left':
        this.leftTarget.click();
        break;
      case 'right':
        this.rightTarget.click();
        break;
      case 'not_applicable':
        this.notApplicableTarget.click();
        break;
    }

    if (this.timeOfServiceValue && this.timeOfServiceValue != 'tos_unknown') {
      this.element.querySelector(`label[for=patient_agreement_product_time_of_service_${this.timeOfServiceValue}]`).click()
    }
  }

  markUnchecked(orientation) {
    orientation.checked = false;
    orientation.parentElement.classList.remove('active', 'focus');
  }

  adjust(event) {
    const { currentTarget } = event;
    if (!currentTarget.checked) {
      currentTarget.blur();
    }

    const state = {
      left: this.leftTarget.checked,
      right: this.rightTarget.checked,
      notApplicable: this.notApplicableTarget.checked,
      bothAllowed: this.bothAllowedValue
    };

    if (state.left && state.right && !state.notApplicable) {
      this.handleBothSidesChecked(currentTarget.id, state.bothAllowed);
    }
    else if (!state.left && state.right && state.notApplicable) {
      this.handleRightAndNotApplicable(currentTarget.id);
    }
    else if (state.left && !state.right && state.notApplicable) {
      this.handleLeftAndNotApplicable(currentTarget.id);
    }
    else if (state.left && state.right && state.notApplicable) {
      this.handleAllChecked(currentTarget.id);
    }
    else if (!state.left && state.right && !state.notApplicable) {
      this.handleOnlyRightChecked(state.bothAllowed);
    }
  }

  handleBothSidesChecked(currentTargetId, bothAllowed) {
    if (!bothAllowed) {
      if (currentTargetId === this.leftTarget.id) this.markUnchecked(this.rightTarget);
      if (currentTargetId === this.rightTarget.id) this.markUnchecked(this.leftTarget);
    }
  }

  handleRightAndNotApplicable(currentTargetId) {
    if (currentTargetId === this.rightTarget.id) this.markUnchecked(this.notApplicableTarget);
    if (currentTargetId === this.notApplicableTarget.id) this.markUnchecked(this.rightTarget);
  }

  handleLeftAndNotApplicable(currentTargetId) {
    if (currentTargetId === this.leftTarget.id) this.markUnchecked(this.notApplicableTarget);
    if (currentTargetId === this.notApplicableTarget.id) this.markUnchecked(this.leftTarget);
  }

  handleAllChecked(currentTargetId) {
    if (currentTargetId === this.notApplicableTarget.id) {
      this.markUnchecked(this.leftTarget);
      this.markUnchecked(this.rightTarget);
    } else {
      this.markUnchecked(this.notApplicableTarget);
    }
  }

  handleOnlyRightChecked(bothAllowed) {
    if (!bothAllowed) {
      this.markUnchecked(this.leftTarget);
    }
    this.markUnchecked(this.notApplicableTarget);
  }

  removeForm() {
    this.enableRentalProduct()
    this.element.parentElement.remove()

    if ($('.inventory-items-list form').length == 0) {
      $('#add-selected-items').addClass('disabled')
    }
  }

  enableRentalProduct() {
    if (!this.hasRentalProductTarget || this.rentalProductTarget.value != '1') return
    
    const serialNumber = this.element.dataset.serialNumber
    const select = document.querySelector('select#inventory_item')
    const matchOption = Array.from(select.options).find(option => option.value === serialNumber);

    if (matchOption) {
      matchOption.disabled = false;
    }
  }
}
