import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { with: String }

  handle(event) {
    let button = event.currentTarget
    let disableWith = this.withValue

    if (button.dataset.disabled || button.classList.contains('button-disabled') ) {
      event.preventDefault()
    } else {
      button.dataset.disabled = true
      button.classList.add('disabled')
      button.innerHTML = disableWith
    }
  }
}
