import { Controller } from "@hotwired/stimulus"
import '@app/file_attachment_drop_zone.js'

export default class extends Controller {

  static targets = [
    'file',
    'otherMethods',
    'filesWrapper',
    'fileName',
    'submitBtn',
    'selectType',
    'uploadFileNames',
    'errorMessage',
    'unhideForm'
  ]

  static values = {
    enabledFileTypes: Array,
    patientAgreementId: String
  }

  connect() {
    this.containErrors = false;
    this.files = new DataTransfer().items;
  }

  dragoverDropzone() {
    new Momd.FileAttachmentDropZone('#attachment-wrapper')
  }

  dragleaveDropzone() {
    $('#attachment-wrapper').removeClass('in hover');
  }

  dropDropzone() {
    $('#attachment-wrapper').removeClass('in hover');
  }

  fileTargetConnected(element) {
    let container = new DataTransfer();

    if(this.files) {
      container.items.add(this.files[element.id]);
      element.files = container.files;

      let fileRow = element.closest('.field-attachment-row')
      fileRow.querySelector('.fileName').innerHTML = this.files[element.id].name
      fileRow.querySelector('.fileData').innerHTML = `${this.files[element.id].size} KB ${this.files[element.id].type}`

      let fileAttachmentObj = element.closest('.field-attachment-row')
      fileAttachmentObj.querySelector('#file-type-icon span').classList.add(this.files[element.id].type)
      this.updateSubmitBtn();
    }
  }

  submitBtnTargetConnected() {
    this.updateSubmitBtn();
  }

  updateSubmitBtn() {
    let fieldAttachmentRows = document.getElementsByClassName('field-attachment-row');
    let selectTypeValues = this.selectTypeTargets.map(target => target.value);

    this.submitBtnTarget.disabled = true

    this.submitBtnTarget.value = `Attach File`
    if (fieldAttachmentRows && fieldAttachmentRows.length > 1) {
      this.submitBtnTarget.value = `Attach ${fieldAttachmentRows.length} Files`
    }

    if (selectTypeValues.length > 0 && !selectTypeValues.includes('')) {
      this.submitBtnTarget.disabled = false
    }

  }

  hideForm() {
    this.uploadFileNamesTarget.textContent = this.fileNameTargets.map(target => target.textContent).join(', ');
    document.querySelector('#attachment-wrapper').classList.add('d-none');
    document.querySelector('.modal-footer').classList.add('d-none');
    document.querySelector('#attachment-uploader').classList.remove('d-none');
  }

  injectFileAttachments(event) {
    const previous_files_count = this.files.length;
    this.containErrors = false;
    this.filterFiles(event.currentTarget.files);

    let fetchParams = `total_files_count=${this.files.length}&previous_files_count=${previous_files_count}`

    if (!this.containErrors) {
      fetch(`/admin/patient_agreements/${this.patientAgreementIdValue}/v2/file_attachments/edit?${fetchParams}`, {
        method: 'GET',
        headers: {
          'Accept': 'text/vnd.turbo-stream.html',
        }
      })
      .then(response => response.text())
      .then(html => Turbo.renderStreamMessage(html))

      if(this.files.length > 0 && this.filesWrapperTarget.classList.contains('d-none')) {
        this.otherMethodsTarget.classList.add('d-none')
        this.filesWrapperTarget.classList.remove('d-none')
      }
      this.errorMessageTarget.textContent = ''
    } else {
      this.errorMessageTarget.textContent = 'One or more files contain invalid file type. Please select a valid file type.'
    }
  }

  removeFileAttachment(event) {
    const attachmentsList = event.currentTarget
                                 .closest('.field-attachment-container')
                                 .querySelectorAll('.field-attachment-row');
    const removingElement = event.currentTarget.closest('.field-attachment-row');
    removingElement.remove();

    if(this.files.length == 0) {
      this.otherMethodsTarget.classList.remove('d-none')
      this.filesWrapperTarget.classList.add('d-none')
    }

    this.updateSubmitBtn();
  }

  clickCompanionApp(event) {
    this.otherMethodsTarget.classList.add('d-none')
  }

  filterFiles(files) {
    let container = new DataTransfer();

    [...Array.from(this.files), ...Array.from(files)].forEach((file, i) => {
      if (this.enabledFileTypesValue.includes(file.type)) {
        container.items.add(file);
      } else {
        this.containErrors = true;
      }
    });

    this.files = container.files;
  }

  unhideFormTargetConnected() {
    this.uploadFileNamesTarget.textContent = this.fileNameTargets.map(target => target.textContent).join(', ');
    document.querySelector('#attachment-wrapper').classList.remove('d-none');
    document.querySelector('.modal-footer').classList.remove('d-none');
    document.querySelector('#attachment-uploader').classList.add('d-none');
  }
}
