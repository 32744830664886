// This is a stimulus version of app/assets/javascripts/admin/dropdown.coffee.
// Any changes to this file should also be made there.

import { Controller } from "@hotwired/stimulus"

const TYPEAHEAD_THRESHOLD = 7;
export default class extends Controller {
  static values = { ajaxOptions: Object }

  connect() {
    this.select2mount();

    document.addEventListener("turbolinks:before-cache", () => {
      this.select2unmount();
    }, { once: true });

    $(document).on('focus', '.select2-selection.select2-selection--single', function (evt) {
      // :enabled is important so tabbing isn't allowed on disabled, and prev is important because of grouped dropdowns like birthdate
      return $(this).closest('.select2-container').prev('select:enabled').select2('open');
    });

    $('select.select2').on('select2:close', function (evt) {
      $('select.select2').on('select2:closing', e => $(e.target).data('select2').$selection.one('focus focusin', e => e.stopPropagation()));
    });
  }

  select2mount() {
    let config = {};
    let additionalClass, modalClass, multipleClass, ariaLabel, ariaRequired;

    config.theme = "default";
    config.placeholder = this.hasPlaceholder(this.element) ? this.placeholder(this.element) : "";
    config.multiple = $(this.element).data("multiple") ? true : false;

    additionalClass = this.additionalClass(this.element) ? $(this.element).data("additionalclass") : ""; // We should be able to use config.dropdownCssClass but its broken in 4.x
    modalClass = this.insideModal(this.element) ? "select2-container--modal" : "";
    multipleClass = $(this.element).data("multiple") ? "select2-container--multiple" : "";
    ariaLabel = $(this.element).attr('aria-label') ? $(this.element).attr('aria-label') : "";
    ariaRequired = $(this.element).attr('aria-required') ? true : false;


    if (!$(this.element).data("multiple")) {
      if (this.hasTypeahead(this.element) && this.hasPlaceholder(this.element)) { // Must have placeholder to clear without error
        config.allowClear = this.hasEmptyOption(this.element) ? true : false;  // There has to be an empty option in order to clear
      } else {
        config.minimumResultsForSearch = Infinity;
      }
    }

    if(this.hasClear(this.element)){
      config.allowClear = this.hasEmptyOption(this.element)
      config.minimumResultsForSearch = this.forceNoTypeahead(this.element) ? -1 : TYPEAHEAD_THRESHOLD;
    }

    // Attach to modal, otherwise typeahead can't focus when inside a modal with tabindex -1
    if(this.insideModal(this.element)) {
      let $modal = $(this.element).parents(".modal");
      if ($modal.attr('id')) {
        config.dropdownParent = $('#'+$modal.attr('id') + " .modal-content");
      }
    }

    let dropdownParent = $(this.element).data("dropdownParent")
    if (dropdownParent) {
      config.dropdownParent = $(dropdownParent)
    }

    if(this.selectData(this.element)) {
      config.data = this.selectData(this.element)
    }

    if (this.escapeMarkup(this.element)) {
      config.escapeMarkup = function(markup) { return markup; }
    }

    if(Object.keys(this.ajaxOptionsValue).length != 0) {
      config.ajax = this.ajaxOptionsValue;
      config.allowClear = this.hasClear(this.element);
      config.ajax.data = function (params) {
        var query = {
          search: params.term,
          page: params.page || 1
        }

        // Query parameters will be ?search=[term]&page=[page]
        return query;
      }
      config.ajax.processResults = function (data, params) {
        var results;
        params.page = params.page || 1;
        results = [];
        $.each(data[Object.keys(data)[0]], function(i, item) {
          return results.push({
            id: item.id,
            text: item.name
          });
        });
        return {
          results: results,
          pagination: {
            more: (params.page * 10) < data.total_count
          }
        };
      }
    }

    // Attaching to Select2 Events
    this.SelectEvent(this.element);
    this.OpeningEvent(this.element);
    this.OpenEvent(this.element);
    this.ClearEvent(this.element);
    this.CloseEvent(this.element);

    if (!$(this.element).hasClass("select2-hidden-accessible")) {
      $(this.element).select2(config);

      // Adding additional classes, select2 no longer supporting a string of theme classes like we handle it on MotionMD, breaking it up here by adding each individually and not attaching to the config object
      if (additionalClass != '') {
        $(this.element).data().select2.$container.addClass(additionalClass);
      }

      if (modalClass != '') {
        $(this.element).data().select2.$container.addClass(modalClass);
      }

      if (multipleClass != '') {
        $(this.element).data().select2.$container.addClass(multipleClass);
      }

      if (ariaLabel != '') {
        $(this.element).data().select2.$container.find('.select2-selection').attr('aria-labelledby', null);
        $(this.element).data().select2.$container.find('.select2-selection').attr('aria-label', ariaLabel);
      }

      if (ariaRequired) {
        $(this.element).data().select2.$container.find('.select2-selection').attr('aria-required', true);
      }

      if (!$(this.element).data("multiple")) {
        if (this.hasTypeahead(this.element) && this.hasPlaceholder(this.element)) {
          $(this.element).data().select2.$container.addClass("select2-container--with-typeahead");
        }
      }

      // Adding a visible empty option with the palceholder to fix tabbing on filters
      if (this.showEmptyOption(this.element) && config.placeholder) {
        let placeholderOption = '<option value=" ">' + config.placeholder + '</option>';
        $(this.element).prepend(placeholderOption).trigger('change');
      }
    }
  }

  select2unmount() {
    this.saveState();
    $(this.element).select2('destroy');
  }

  SelectEvent(dropdown) {
    $(dropdown).on('select2:select', function (evt) {
      let event = new Event('change', { bubbles: true }); // fire a native event
      this.dispatchEvent(event);
    });
  }

  OpeningEvent(dropdown) {
    $(dropdown).on('select2:opening', function (evt) {
      const $container = $(evt.target).data('select2').dropdown.$searchContainer;
      // if clearing, prevent opening the select2

      if (!$(dropdown).data("multiple")) {
        if ($container.data('unselecting')) {
          return false;
        }
      }
    });
  }

  OpenEvent(dropdown) {
    let controller = this
    // When dropdown does not have the typeahead, if the user types when a dropdown is open, show the typeahead and give it focus (recreating the default select box type to highlight behavior)
    $(dropdown).on('select2:open', function (evt) {
      const context = $(evt.target).data('select2');
      if (controller.hasTypeahead(dropdown)) {
        context.dropdown.$searchContainer.addClass('select2-search--force-show');
        document.querySelector(`[aria-controls="select2-${evt.target.id}-results"]`).focus();
      }

      $(document).on('keydown.select2', function (e) {
        const target = context.$container;

        // This is only for enabled dropdowns that don't @hasTypeahead
        if (!target.length || target.hasClass('select2-container--with-typeahead') || !target.prev('select:enabled')) {
          return;
        }

        let key = 0;

        // Safari does not have key ###
        if (e.key) {
          key = e.key;
        } else {
          key = String.fromCharCode(e.which);
        }

        if (key.length == 1) {
          // When user types a character we give the search box focus and show it
          context.dropdown.$searchContainer.addClass('select2-search--force-show');
          document.querySelector(`[aria-controls="select2-${evt.target.id}-results"]`).focus();
        } else {
          return;
        }
      });

      return;
    });
  }

  ClearEvent(dropdown) {
    // Preventing the dropdown from reopening when clearing
    $(dropdown).on('select2:clear', function (evt) {
      $(evt.target).data('select2').dropdown.$searchContainer.data('unselecting', true);
      var event = new CustomEvent('change', {bubbles: true, cancelable: true});
      evt.target.dispatchEvent(event);
    });
  }

  CloseEvent(dropdown) {
    // Best current way to select on tab is to hijack the close event and use a keydown
    $(dropdown).on('select2:close', function (evt) {
      const context = $(evt.target);
      const $container = context.data('select2').dropdown.$searchContainer;
      const currentVal = context.data('select2').$element.val();
      // Remove the force-show class on the search container (added in the open event)
      if ($container) {
        $container.removeClass('select2-search--force-show')
        $(document).on('keydown.select2', function (e) {
          if (e.which == 9) {
            // tab
            const highlighted = context.data('select2').$dropdown.find('.select2-results__option--highlighted');
            if (highlighted && highlighted.data('select2-id')) {
              if (highlighted.data('select2-id').toString().indexOf("-") > -1) {
                const id = highlighted.data('select2-id').split('-').pop();

                // Do not trigger change event if the hovered option is the same as the selection
                if (id.trim() != currentVal.trim()) {
                  context.val(id).trigger('change');
                }
              }
            }
          }
          return;
        });
        // Unbind the event again to avoid binding multiple times + reset unselecting attribute
        setTimeout(() => {
          $container.data('unselecting', null);
          $(document).off('keydown.select2');
          return;
        }, 1)
        return;
      } else {
        return;
      }
    });
  }

  saveState() {
    let values = $(this.element).val();

    // make sure the HTML itself has those elements selected, since the HTML is what is saved in the turbolinks snapshot
    values.forEach((val) => {
      $(this.element).find('option[value="${val}"]').attr('selected', 'selected');
    })
  }

  optionCount(select) {
    return $(select).children("option").length;
  }

  hasEmptyOption(select) {
    return !!$(select).find("option[value='']").length;
  }

  hasPlaceholder(select) {
    return !!this.placeholder(select)
  }

  placeholder(select) {
    return $(select).data("placeholder") || $(select).attr("placeholder");
  }

  hasOptions(select) {
    return this.optionCount(select) > 0;
  }

  hasTypeahead(select) {
    return ((this.optionCount(select) > TYPEAHEAD_THRESHOLD
    ) && !this.forceNoTypeahead(select)) || this.forceTypeahead(select);
  }

  hasClear(select) {
    return !!$(select).data("allowclear");
  }

  additionalClass(select){
    return $(select).data("additionalclass");
  }

  showEmptyOption(select) {
    return $(select).data("showemptyoption");
  }

  insideModal(select) {
    return $(select).parents(".modal-body").length;
  }

  forceTypeahead(select) {
    return $(select).data("forcetypeahead");
  }

  forceNoTypeahead(select) {
    return $(select).data("forcenotypeahead");
  }

  selectData(select) {
    return $(select).data("selectdata");
  }

  escapeMarkup(select) {
    return $(select).data("escapemarkup");
  }
}
