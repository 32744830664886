import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
  }

  open(event) {
    const $target = $(event.target)
    const modalId = $target.data("modal")
    if(!!modalId) {
      $(modalId).modal("show")
    } else {
      BigSpinner.finishedTimer = setTimeout(BigSpinner.finished, 3000)
      BigSpinner.add()
    }
  }
}
