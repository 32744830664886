import { Controller } from "@hotwired/stimulus"
import { post, get } from '@rails/request.js'

export default class extends Controller {
  static values = { requirementsPath: String }
  static targets = ['requirements', 'clinicSelect', 'rentalProduct', 'protocolDetails', 'toggleProtocolButton', 'diagnosesSection', 'diagnosesList', 'productsSection', 'productsList', 'productCard']

  connect() {}

  requirementsTargetConnected() {
    this._toggleCreateButton(this.requirementsTarget.dataset.valid === "true");
  }

  updateSidebar(event) {
    post(this.requirementsPathValue, {
      body: new FormData(this.element),
      responseKind: "turbo-stream"
    })
  }

  fetchProtocols(event) {
    const medical_professional_id = event.target.value;
    if (medical_professional_id) {
      get(`/admin/patient_agreements/fetch_protocols?medical_professional_id=${medical_professional_id}`, {
        responseKind: "turbo-stream"
      });
    } else {
      document.getElementById('protocols').innerHTML = '';
    }
  }

  toggleProtocolDetails(event) {
    const protocolDetails = this.protocolDetailsTarget;
    const toggleButton = this.toggleProtocolButtonTarget;

    if (protocolDetails.classList.contains('d-none')) {
      protocolDetails.classList.remove('d-none');
      toggleButton.textContent = 'Cancel Protocol';
    } else {
      protocolDetails.classList.add('d-none');
      toggleButton.textContent = 'Use Protocol';
    }
  }

  _toggleCreateButton(formComplete) {
    if (formComplete) {
      $('.submit-button').prop('disabled', false);
    } else {
      $('.submit-button').prop('disabled', true);
    }
  }

  fetchDiagnoses(event) {
    const protocolId = event.target.value;
    if (protocolId) {
      get(`/admin/patient_agreements/fetch_diagnoses?protocol_id=${protocolId}`, {
        responseKind: "turbo-stream"
      }).then(() => {
        this.diagnosesSectionTarget.classList.remove('d-none');
      });
    } else {
      this.diagnosesSectionTarget.classList.add('d-none');
      this.diagnosesListTarget.innerHTML = '';
    }
  }

  fetchProducts(event) {
    const protocolId = event.target.value;
    if (protocolId) {
      get(`/admin/patient_agreements/fetch_products?protocol_id=${protocolId}`, {
        responseKind: "turbo-stream"
      }).then(() => {
        this.productsSectionTarget.classList.remove('d-none');
      });
    } else {
      this.productsSectionTarget.classList.add('d-none');
      this.productsListTarget.innerHTML = '';
    }
  }

  toggleSelected(event) {
    const checkbox = event.target;
    const card = checkbox.closest(".card");

    if (checkbox.checked) {
      card.classList.add("selected");
    } else {
      card.classList.remove("selected");
    }
  }

  removeRentalProduct(event) {
    let msg = event.target.dataset.confirm
    this.rentalProductTarget.remove()
    history.replaceState({}, '', location.pathname)
  }
}
