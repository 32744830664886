import { get, patch } from '@rails/request.js'
import { Controller } from "@hotwired/stimulus"

class PaymentMethodSectionController extends Controller {
  static targets = ["editPaymentMethod"]

  static values = {
    patientAgreementId: Number
  }

  static singletonWebsocketConnectionExists = false

  connect() {
    $(this.element).on('sortable:create', function() {
      $("#sortable-payment-methods").sortable({
        handle: '.payment-method',
        opacity: 0.6,
        placeholder: 'ui-state-payment-placeholder',
        tolerance: 'pointer',
        items: '.handle',
        cancel: '.momd-card__hidden, input, select, .select2-container, button, a, .dropdown'
      });

      $('#sortable-payment-methods').sortable({
        stop: async (event, ui) => {
          const path = $('#sortable-payment-methods').data('reposition-path');

          let updatedPositions = {}
          $('.payment-method').map(function(i) {
            updatedPositions[i] = {
              id: $(this).data('paymentMethodCardPaymentMethodIdValue'),
              position: i + 1
            };
          });

          const ajaxCall = await patch(path, {
            body: { reposition: updatedPositions },
            responseKind: "js"
          })
        }
      });
    });

    $(this.element).trigger('sortable:create');

    this.initSingletonWebsocketConnection()
  }

  healthIpassRefreshPath(responseData) { 
    return "/admin/patient_agreements/"+responseData.patient_agreement_id+"/charges/refresh"
  }

  initSingletonWebsocketConnection() {
    if (!PaymentMethodSectionController.singletonWebsocketConnectionExists) {
      const pusher = window.getPusher();
      const controller = this;

      const paId = this.patientAgreementIdValue;
      const channel = pusher.subscribe('private-patient-agreement-'+paId+'-channel');

      channel.bind('benefits-status-update', data => {
        if ($(`#payment_method_${data.payment_method_id}`).length) {
          if (data.response_has_benefits_selected) {
            $(`#payment_method_${data.payment_method_id} .self-pay.new_charge`).removeClass("loading button-disabled").tooltip('disable');
            return this.enableChargeButton($(`#payment_method_${data.payment_method_id} .self-pay.new_record_charge`));
          } else {
            $(`#payment_method_${data.payment_method_id} .self-pay.new_charge`).removeClass("loading").addClass("button-disabled").tooltip('enable');
            return $(`#payment_method_${data.payment_method_id} .self-pay.new_record_charge`).removeClass("loading").addClass("button-disabled").tooltip('enable');
          }
        }
      });

      channel.bind('same-or-similar-response', async function(response) {
        if (paId === response.patient_agreement_id) {
          await get(controller.sameOrSimilarResponsePath(response), { responseKind: "turbo-stream" })
          return $('#attachments-wrapper').trigger('refreshAttachments');
        }
      });

      channel.bind('file-attachments-changed', function(response) {
        if (paId === response.patient_agreement_id) {
          return $('#attachments-wrapper').trigger('refreshAttachments');
        }
      });

      channel.bind('health-ipass-response', (response) => {
        if (paId === response.patient_agreement_id) {
          if (response.get_payment_url) {
            return window.open(response.get_payment_url, '_blank');
          } else {
            get(this.healthIpassRefreshPath(response), { responseKind: "turbo-stream" })
          }
        }
      });

      PaymentMethodSectionController.singletonWebsocketConnectionExists = true;
    }
  }

  enableChargeButton($chargeButton) {
    let new_tooltip_text;
    if ((new_tooltip_text = $chargeButton.data("missing-required-patient-fields-tooltip"))) {
      return $chargeButton.attr("data-original-title", new_tooltip_text);
    } else {
      return $chargeButton.removeClass("loading button-disabled").tooltip('disable');
    }
  }

  editPaymentMethodTargetConnected() {
    $("#turbo-modal").modal("show")
  }

  sameOrSimilarResponsePath(responseData) {
    return "/admin/patient_agreements/"+responseData.patient_agreement_id+"/" +
      "same_or_similar_requests/"+responseData.same_or_similar_request_id+"/" +
      "same_or_similar_responses/"+responseData.same_or_similar_response_id
  }
}

export default PaymentMethodSectionController;
