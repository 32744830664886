import { get, patch, post } from "@rails/request.js"
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    'addItemsWrapper',
    'inventoryItems',
    'qtyInput',
    'skuSelect',
    'addItem',
    'submitButton'
  ]

  connect() {
    this.patientAgreementID = $('.page-header').data('id')
    this.csrfToken = document.querySelector('meta[name="csrf-token"]').content
    this.productType = $(this.addItemTarget).data('product-type')
    if (this.productType == 'custom') {
      this.addCustom()
    } else {
      this.autoAddItems()
    }
  }

  resetAddForm() {
    this.qtyInputTarget.value = 1
    $(this.skuSelectTarget).val("").trigger("change")
    this.submitButtonTarget.classList.remove('disabled')
  }

  updatePage() {
    if ($('.products-panel').find('.panel__body .card').length) {
      $('#products-search-section')[0].scrollIntoView({block: 'center', behavior: 'smooth'})
    }
  }

  validateForm(form) {
    let isValid = true;

    const $fulfillFrom = $(form).find('[data-pa-product-selection-target="fulfillFrom"]');
    const $billingChannel = $(form).find('[data-pa-product-selection-target="billingChannel"]');
    const $inventoryLocation = $(form).find('[data-pa-product-selection-target="inventoryLocation"]');
    const $rentalPeriod = $(form).find('[data-pa-product-selection-target="rentalPeriod"]');
    const $orientations = $(form).find('[data-pa-product-selection-target="orientation"]');
    const $orientationChecked = $(form).find("input[data-pa-product-selection-target='orientation']:checked").val()

    const validateInput = ($input) => {
      if ($input.length && !$input.val()) {
        $input.parents(".col-form").addClass("error");
        isValid = false;
      } else {
        $input.parents(".col-form").removeClass("error");
      }
    };

    validateInput($fulfillFrom);
    validateInput($billingChannel);
    validateInput($inventoryLocation);
    validateInput($rentalPeriod);

    if (!$orientationChecked) {
      $orientations.parents(".col-form").addClass("error");
      isValid = false;
    } else {
      $orientations.parents(".col-form").removeClass("error");
    }
    
    return isValid;
  }

  async refreshProducts() {
    const ajaxCall = await get(`/admin/patient_agreements/patient_agreement_products?patient_agreement_id=${this.patientAgreementID}`, {
      responseKind: "turbo-stream"
    }).then((response) => this.updatePage())
    .then(() => BigSpinner.finished())
  }

  async addCustom() {
    this.addItemsWrapperTarget.classList.add('d-none')

    BigSpinner.add()
    const ajaxCall = await get(`${this.addItemTarget.dataset.url}&patient_agreement_id=${this.patientAgreementID}&qty=1&sku=0`, {
      responseKind: "turbo-stream"
    }).then((response) => this.resetAddForm())
    .then(() => BigSpinner.finished())
  }

  async addItems(event) {
    if (this.skuSelectTarget.value != '') {
      this.disableRentalProduct()

      let productParam = `${this.productType === 'rental' ? 'serial_number' : 'sku'}=${this.skuSelectTarget.value}`
      let url = `${event.target.dataset.url}&patient_agreement_id=${this.patientAgreementID}&qty=${$('.inventory-quantity').val()}&type=${this.productType}&${productParam}`
      $(this.skuSelectTarget).val("").trigger("change")

      BigSpinner.add()
      const ajaxCall = await get(url, {
        responseKind: "turbo-stream"
      }).then((response) => this.resetAddForm())
      .then(() => BigSpinner.finished())
    }
  }

  async submitForm(event) {
    const forms = this.inventoryItemsTarget.querySelectorAll('.edit-patient-agreement-product-form');
    let url = forms[0].getAttribute('action');
    let allFormsValid = true;
    let params = new FormData();

    forms.forEach((form) => {
      if (this.validateForm(form)) {
        let formData = new FormData(form);
        for (const pair of formData.entries()) {
          let [key, val] = pair;
          params.append(key, val);
        }
      } else {
        allFormsValid = false;
      }
    });

    if (allFormsValid) {
      await post(url, {
        responseKind: "turbo-stream",
        body: params
      });
    }
  }

  autoAddItems() {
    if (this.skuSelectTarget.value != '') {
      this.addItemTarget.click()
    }
  }
  
  disableRentalProduct() {
    if (this.productType != 'rental') return
    
    const selectedOption = this.skuSelectTarget.options[this.skuSelectTarget.selectedIndex]
    selectedOption.disabled = true;
  }
}
