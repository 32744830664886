import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    'searchWrapper',
    'form',
    'criteria',
    'clearSearch',
    'resultsSection'
  ];

  resetForm() {
    $(this.resultsSectionTarget).html('')
    $(this.criteriaTarget).val('').focus()
    this.searchWrapperTarget.scrollIntoView({ behavior: 'smooth' })
  }

  submitStart() {
    BigSpinner.add()
  }
  
  submitEnd() {
    this.searchWrapperTarget.scrollIntoView()
    this.criteriaTarget.blur()
    BigSpinner.finished()
  }
}